import React from 'react';
import Scroll from '../common/Scroll';

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
      <h1>sbc studio</h1>
      <p>
        We start, build and launch your next favourite commerce brands.
      </p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="two">
            <a href="#one" className="button">
              Learn more
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
