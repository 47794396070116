import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      heading: 'Custom Apparel',
      description: 'design, source, print and ship popular apparel and promotional items for brands and influencers.',
      iconClass: 'fa-code',
    },
    {
      heading: 'Influcence Marketing',
      description: 'collaborate with influencers on campaign management and events.',
      iconClass: 'fa-lock',
    },
    {
      heading: 'Event Management',
      description: 'coordinate and facilitate online and offline experiences.',
      iconClass: 'fa-cog',
    },
    {
      heading: 'Startup Branding',
      description: 'modern brand identity visuals to get brands started.',
      iconClass: 'fa-desktop',
    },
    {
      heading: 'Social Commerce',
      description: 'create social-first shopping experiences for dtc brands.',
      iconClass: 'fa-chain',
    },
    {
      heading: 'Content Creation',
      description: 'graphics, social posts, video production and podcasts.',
      iconClass: 'fa-diamond',
    },
  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.heading} {...capability} />)}
    </>
  )
}

export default CapabilityList;
